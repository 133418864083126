const apiBaseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v${process.env.REACT_APP_API_VERSION}`;

// auth
export const registerUrl = `${apiBaseUrl}/company/register`;
export const loginUrl = `${apiBaseUrl}/company/login`;

// profile
export const profileUrl = `${apiBaseUrl}/profile/info`;
export const addWalletUrl = `${apiBaseUrl}/profile/wallets`;
export const delWalletUrl = `${apiBaseUrl}/profile/wallets`;
export const urlWalletUrl = `${apiBaseUrl}/profile/urls`;
export const transUrl = `${apiBaseUrl}/txs`;

// general
export const chainListUrl = `${apiBaseUrl}/chain`;

// address and balance
export const addrListUrl = `${apiBaseUrl}/balance/addresses`;
export const withdrawUrl = `${apiBaseUrl}/balance/withdraw`;

// app
export const addrRequestUrl = `${apiBaseUrl}/app/address`;
export const statusPollingUrl = `${apiBaseUrl}/app/status`;
export const verifInfoUrl = `${apiBaseUrl}/app/txverif`;
export const orderInfoUrl = `${apiBaseUrl}/app/order`;
