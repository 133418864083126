//router
// import IndexRouters from "./router/index"

//scss
import './assets/scss/hope-ui.scss';
import './assets/scss/custom.scss';
import './assets/scss/dark.scss';
import './assets/scss/rtl.scss';
import './assets/scss/customizer.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-loading-skeleton/dist/skeleton.css';

// Redux Selector / Action
import { useDispatch } from 'react-redux';

// import state selectors
import { setSetting } from './store/setting/actions';
import { InitializeUser } from './helpers';

function App({ children }) {
  const dispatch = useDispatch();
  dispatch(setSetting());
  return (
    <div className="App">
      {/* <IndexRouters /> */}
      {children}
    </div>
  );
}

export default App;
