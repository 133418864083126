import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const withAuth = (WrappedComponent) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
      const savedUser = localStorage.getItem('user');
      const user = savedUser && JSON.parse(savedUser);

      if (!user) {
        navigate('/auth/sign-in'); // Redirect to login if not authenticated
      }
    }, [navigate]);

    return <WrappedComponent {...props} />;
  };

  return Wrapper;
};

export const withNotAuth = (WrappedComponent) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
      const savedUser = localStorage.getItem('user');
      const user = savedUser && JSON.parse(savedUser);

      if (user) {
        navigate('/profile'); // Redirect to profile if authenticated
      }
    }, [navigate]);

    return <WrappedComponent {...props} />;
  };

  return Wrapper;
};
