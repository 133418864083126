import React, { useState, useEffect, Fragment } from 'react';
import { Button, Nav, Collapse, Navbar, Container, Image, Row, Col, Form } from 'react-bootstrap';

//img
import topImage from '../../assets/images/dashboard/top-image.jpg';

//prism
import '../../../node_modules/prismjs/prism';
import '../../../node_modules/prismjs/themes/prism-okaidia.css';

// SliderTab
import SliderTab from '../../plugins/slider-tabs';

// Import selectors & action from setting store
import * as SettingSelector from '../../store/setting/selectors';

// Redux Selector / Action
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { addrRequestUrl, verifRequestUrl } from '../../consts';
import { useApiRequest } from '../../hooks';
import { QRCodeCanvas } from 'qrcode.react';
import { Toaster } from '../../helpers';

import TexitLogo from '../../assets/img/texit_logo_white.png';
import PureLogo from '../../assets/img/pure_logo_white.png';

const PreCheckoutPage = () => {
  const [height, setHeight] = useState('auto');
  const appName = useSelector(SettingSelector.app_name);
  const location = useLocation();
  const navigate = useNavigate();

  // get URL params
  const { apikey } = useParams();

  useEffect(() => {
    const handleResize = () => {
      const parentHeight = document.documentElement.scrollHeight; // or another parent element's height
      const viewportHeight = window.innerHeight;

      // Set height to the maximum of parent height and viewport height
      setHeight(Math.max(parentHeight, viewportHeight));
    };

    // Initial resize calculation
    handleResize();

    // Event listener for window resizing
    window.addEventListener('resize', handleResize);

    // Cleanup on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSelect = (crypto) => {
    setTimeout(() => {
      navigate(`/app/checkout/${apikey}/${crypto}/${location.search}`);
    }, 500);
  };

  return (
    <Fragment>
      <span className="uisheet screen-darken"></span>
      <div
        className="header"
        style={{
          background: `url(${topImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'repeat-y',
          position: 'relative',
          height: `${height}px`,
        }}
      >
        {/* <div className="main-img">
          <div className="container">
            <Image src="/kryptopay-icon.svg" width={160} />
            <h1 className="my-4">
              <span data-setting="app_name">{appName}</span>
            </h1>
            <h4 className="text-white mb-5">
              Production ready <b>Kryptopay Payment Gateway</b>
            </h4>
          </div>
        </div> */}

        <Container>
          <Nav className="rounded navbar navbar-expand-lg navbar-light top-1">
            <Container>
              <Navbar.Brand href="/" className="mx-2 d-flex align-items-center">
                <Image src="/kryptopay-icon.svg" width={30} />
                <h5 className="logo-title mx-3">{appName}</h5>
              </Navbar.Brand>
              {/* <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setOpen3(!open3)} />
              <Navbar.Collapse id="basic-navbar-nav" in={open3}>
                <ul className="mb-2 navbar-nav ms-auto mb-lg-0 d-flex align-items-start">
                  <Nav.Item as="li" className="me-3 mb-2 mb-sm-0">
                    <Nav.Link href="/auth/sign-in">Sign in</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="me-3 mb-2 mb-sm-0">
                    <Nav.Link href="/auth/sign-up">Sign up</Nav.Link>
                  </Nav.Item>
                </ul>
              </Navbar.Collapse> */}
            </Container>
          </Nav>
        </Container>
        <Container className="py-3">
          <Row className="mx-2 ">
            <div className="text-center my-4 text-white">Select a cryptocurrency you want to pay with.</div>
          </Row>
          <Row>
            <Col className="text-center">
              <div type="button" className="btn btn-primary btn-lg mx-2" onClick={() => handleSelect('txc')}>
                <Image src={TexitLogo} height={20} className="me-1"></Image>
                <span style={{ verticalAlign: 'middle' }}>Texit Coin</span>
              </div>
              {/* <div type="button" className="btn btn-primary btn-lg mx-2" onClick={() => handleSelect('pure')}>
                <Image src={PureLogo} height={20} className="me-1"></Image>
                <span style={{ verticalAlign: 'middle' }}>Pure Medal</span>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default PreCheckoutPage;
