import React, { useState, useEffect, Fragment } from 'react';
import { Nav, Navbar, Container, Image, Row, Col, Form, Card } from 'react-bootstrap';
import Progress from '../../components/progress.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRotateRight,
  faCheckDouble,
  faCircleCheck,
  faEnvelope,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';

// Redux Selector / Action
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { addrRequestUrl, statusPollingUrl } from '../../consts';
import { useApiRequest } from '../../hooks';
import { QRCodeCanvas } from 'qrcode.react';
import { Toaster } from '../../helpers';

//img
import topImage from '../../assets/images/dashboard/top-image.jpg';

//prism
import '../../../node_modules/prismjs/prism';
import '../../../node_modules/prismjs/themes/prism-okaidia.css';

// SliderTab
import SliderTab from '../../plugins/slider-tabs';

// Import selectors & action from setting store
import * as SettingSelector from '../../store/setting/selectors';

const CheckoutPage = () => {
  const appName = useSelector(SettingSelector.app_name);

  // collapse
  // const [open3, setOpen3] = useState(false);

  // states
  const [wallet, setWallet] = useState('');
  const [total, setTotal] = useState(0);
  const [status, setStatus] = useState('waiting');
  const [txid, setTxid] = useState(undefined);
  const [hookInfo, setHookInfo] = useState(null);
  const [remain, setRemain] = useState(parseInt(process.env.REACT_APP_TIME_THRESHOLD));

  // navigate
  const navigate = useNavigate();

  // get URL params
  const { apikey, crypto } = useParams();

  // get query params
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const uid = queryParams.get('uid');
  const uname = queryParams.get('uname');
  const umail = queryParams.get('umail');
  const oid = queryParams.get('oid');
  const price = queryParams.get('price');
  const refer = queryParams.get('refer');

  const [height, setHeight] = useState('auto');

  let pollTimer;
  let counterTimer;

  useEffect(() => {
    const handleResize = () => {
      const parentHeight = document.documentElement.scrollHeight; // or another parent element's height
      const viewportHeight = window.innerHeight;

      // Set height to the maximum of parent height and viewport height
      setHeight(Math.max(parentHeight, viewportHeight));
    };

    // Initial resize calculation
    handleResize();

    // Event listener for window resizing
    window.addEventListener('resize', handleResize);

    // Cleanup on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // send address request
  const {
    response: addrResponse,
    error: addrError,
    loading: addrLoading,
    sendRequest: sendAddrRequest,
  } = useApiRequest({
    endpoint: addrRequestUrl,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'x-api-key': apikey,
    },
    data: {
      crypto,
      price,
      uid,
      uname,
      umail,
      oid,
      refer,
    },
  });

  // send polling request
  const { response: pollingResponse, sendRequest: sendPollingRequest } = useApiRequest({
    endpoint: statusPollingUrl,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'x-api-key': apikey,
    },
    params: {
      address: wallet,
      crypto,
    },
  });

  const pollStatus = (id) => {
    clearInterval(pollTimer);
    pollTimer = setInterval(() => {
      sendPollingRequest({}, { txid: id });
    }, 10000);
  };

  const startCounter = () => {
    counterTimer = setInterval(() => {
      setRemain((prev) => (prev === 0 ? 0 : prev - 1));
    }, 1000);
  };

  // send request for wallet address
  useEffect(() => {
    apikey && crypto && apikey.length === 40 && sendAddrRequest();
  }, [apikey, crypto]);

  // wallet address response
  useEffect(() => {
    addrResponse && addrResponse.data && addrResponse.data.addr && setWallet(addrResponse.data.addr);
    addrResponse && addrResponse.data && addrResponse.data.total && setTotal(addrResponse.data.total);
  }, [addrResponse]);

  // wallet address error
  useEffect(() => {
    addrError && setWallet('');
    addrError && Toaster.error(addrError.message);
  }, [addrError]);

  // counter after wallet valid
  useEffect(() => {
    wallet !== '' && startCounter();

    return () => {
      clearInterval(counterTimer);
    };
  }, [wallet]);

  // polling
  useEffect(() => {
    wallet !== '' && pollStatus(txid);

    return () => {
      clearInterval(pollTimer);
    };
  }, [txid, wallet]);

  // polling response
  useEffect(() => {
    pollingResponse && setStatus(pollingResponse.data.status);
    pollingResponse && setTxid(pollingResponse.data.txid);
    pollingResponse && pollingResponse.data.remain && setRemain(pollingResponse.data.remain);
    pollingResponse && pollingResponse.data.status === 'completed' && setHookInfo(pollingResponse.data.hookInfo);
  }, [pollingResponse]);

  // if status is completed
  useEffect(() => {
    if (status === 'completed') {
      clearInterval(pollTimer);
      clearInterval(counterTimer);
      if (hookInfo) {
        if (hookInfo.partial) {
          Toaster.success(hookInfo.message);
          setTimeout(
            navigate,
            5000,
            `/app/checkout/${apikey}/?uid=${uid}&uname=${uname}&umail=${umail}&oid=${oid}&price=${
              hookInfo.remaining
            }&refer=${encodeURIComponent(refer)}`
          );
        } else {
          Toaster.success(hookInfo.message);
          setTimeout(navigate, 5000, `/app/checkout-confirm/${apikey}/${oid}/?refer=${encodeURIComponent(refer)}`);
        }
      }
    } else if (status === 'expired') {
      clearInterval(pollTimer);
      clearInterval(counterTimer);

      Toaster.success('This address has been expired');
      setTimeout(
        navigate,
        5000,
        `/app/checkout/${apikey}/?uid=${uid}&uname=${uname}&umail=${umail}&oid=${oid}&price=${price}&refer=${encodeURIComponent(
          refer
        )}`
      );
    }
  }, [status, hookInfo]);

  // slider
  useEffect(() => {
    return () => {
      setTimeout(() => {
        Array.from(document.querySelectorAll('[data-toggle="slider-tab"]'), (elem) => {
          return new SliderTab(elem);
        });
      }, 100);
    };
  });

  return (
    <Fragment>
      <span className="uisheet screen-darken"></span>
      <div
        className="header"
        style={{
          background: `url(${topImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'repeat-y',
          position: 'relative',
          height: `${height}px`,
        }}
      >
        <Container>
          <Nav className="rounded navbar navbar-expand-lg navbar-light top-1">
            <Container>
              <Navbar.Brand href="/" className="mx-2 d-flex align-items-center">
                <Image src="/kryptopay-icon.svg" width={30} />
                <h5 className="logo-title mx-3">{appName}</h5>
              </Navbar.Brand>
              {/* <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setOpen3(!open3)} />
              <Navbar.Collapse id="basic-navbar-nav" in={open3}>
                <ul className="mb-2 navbar-nav ms-auto mb-lg-0 d-flex align-items-start">
                  <Nav.Item as="li" className="me-3 mb-2 mb-sm-0">
                    <Nav.Link href="/auth/sign-in">Sign in</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="me-3 mb-2 mb-sm-0">
                    <Nav.Link href="/auth/sign-up">Sign up</Nav.Link>
                  </Nav.Item>
                </ul>
              </Navbar.Collapse> */}
            </Container>
          </Nav>
        </Container>
        <Container className="py-3">
          <Row className="mx-2 ">
            <Col md="6" sm="12" className="justify-content-center text-center mt-3">
              {addrLoading && (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '30vh' }}>
                  Loading a payment address...
                </div>
              )}
              {!addrLoading && wallet !== '' && (
                <>
                  <div style={{ fontSize: '72px', color: 'yellow' }}>{`$ ${(parseFloat(price) / 100).toFixed(2)}`}</div>
                  <div className="text-white d-flex justify-content-center align-items-center mb-3">
                    You need to pay
                    <span style={{ color: 'yellow', fontSize: '2rem' }} className="mx-2">
                      {(Math.ceil(total * 1000000) / 1000000).toFixed(6)} {crypto.toUpperCase()}
                    </span>
                    to this address.
                  </div>
                  <QRCodeCanvas
                    value={`texitcoin:${wallet}`}
                    // value={`texitcoin:${wallet}?amount=${Math.ceil(total * 1000000) / 1000000}`}
                    size={300}
                    marginSize={4}
                    level="H"
                    imageSettings={{
                      src: '/txc-logo.png',
                      height: '75',
                      width: '75',
                      excavate: true,
                    }}
                  />
                  <Form className="comment-text d-flex align-items-center mt-3 mx-3" action="">
                    <Form.Control
                      type="text"
                      className="rounded text-center"
                      defaultValue={wallet}
                      disabled
                      style={{ color: '#333333' }}
                    />
                  </Form>
                </>
              )}
              {!addrLoading && wallet === '' && (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '30vh' }}>
                  Payment is not available using this cryptocurrency.
                </div>
              )}
            </Col>
            <Col md="6" sm="12" className="justify-content-center text-center mt-3">
              {!addrLoading && (
                <>
                  <div className="text-white d-flex justify-content-center align-items-center mt-1">
                    You have remaining&nbsp;&nbsp;
                    <span style={{ color: 'red', fontSize: '2rem', fontFamily: 'monospace' }}>{`${Math.floor(
                      remain / 3600
                    )
                      .toString()
                      .padStart(2, '0')}${':'}${Math.floor((remain - Math.floor(remain / 3600) * 3600) / 60)
                      .toString()
                      .padStart(2, '0')}${':'}${(remain - Math.floor(remain / 60) * 60)
                      .toString()
                      .padStart(2, '0')}`}</span>
                  </div>
                  <div className="text-warning mt-1 mb-2">
                    <FontAwesomeIcon icon={faWarning} className="me-1" />
                    Caution: Please make sure that you pay the same price you ordered in the store. We will process your
                    payment automatically though you close this page after payment. If your payment amount is less than
                    the order price, the order will be marked as partially paid and you need to pay more afterwards. The
                    confirmation might take 10 minutes.
                  </div>
                  <Card className="text-start">
                    <Card.Header className="d-flex justify-content-start">
                      <div className="header-title">
                        <h4 className="card-title">Payment in progress</h4>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <div className="mb-4">
                        <Progress
                          softcolors="primary"
                          color="primary"
                          className="shadow-none w-100"
                          value={
                            status === 'waiting' ? 0 : status === 'confirming' ? 33 : status === 'notifying' ? 66 : 100
                          }
                          minvalue={0}
                          maxvalue={100}
                          style={{ height: '6px' }}
                        />
                      </div>
                      <div className="d-flex justify-content-start">
                        <div className="pe-3">
                          <FontAwesomeIcon
                            icon={status === 'waiting' ? faArrowRotateRight : faCircleCheck}
                            size="2x"
                            spin={status === 'waiting'}
                            className={status === 'waiting' ? '' : 'text-success'}
                          />
                        </div>
                        <div>
                          <h6 className="mb-1">Waiting for a transaction</h6>
                          <p className="mb-0">
                            Once transaction is initialized, we'll check the confirmation of the transaction.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-start mt-4">
                        <div className="pe-3">
                          <FontAwesomeIcon
                            icon={
                              status === 'confirming'
                                ? faArrowRotateRight
                                : status === 'waiting'
                                ? faCheckDouble
                                : faCircleCheck
                            }
                            size="2x"
                            spin={status === 'confirming'}
                            className={status === 'waiting' || status === 'confirming' ? '' : 'text-success'}
                          />
                        </div>
                        <div>
                          <h6 className="mb-1">Checking confirmation</h6>
                          <p className="mb-0">
                            The transaction will be valid after several confirmation. This will take several minutes.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-start mt-4">
                        <div className="pe-3">
                          <FontAwesomeIcon
                            icon={
                              status === 'notifying'
                                ? faArrowRotateRight
                                : status === 'waiting' || status === 'confirming'
                                ? faEnvelope
                                : faCircleCheck
                            }
                            size="2x"
                            spin={status === 'notifying'}
                            className={status === 'completed' ? 'text-success' : ''}
                          />
                        </div>
                        <div>
                          <h6 className="mb-1">Notifying payment to the shop</h6>
                          <p className="mb-0">Your payment will be notified to the shop you are paying for.</p>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </>
              )}
              {addrLoading && (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '30vh' }}>
                  Loading payment details...
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default CheckoutPage;
