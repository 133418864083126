import React, { useState, useEffect, Fragment } from 'react';
import { Button, Nav, Collapse, Navbar, Container, Image } from 'react-bootstrap';
import Card from '../../src/components/Card';
import Logo from '../components/partials/components/logo';
import { Link } from 'react-router-dom';

//uiKit
import Accordions from './uikit/accordion';
import Alerts from './uikit/alert';
import Badges from './uikit/badge';
import Breadcrumbs from './uikit/breadcrumb';
import Buttons from './uikit/button';
import ButtonGroups from './uikit/buttons-group';
import Calenders from './uikit/calender';
import Cards from './uikit/card';
import Carousels from './uikit/carousel';
import DropDowns from './uikit/dropdowns';
import ListGroups from './uikit/list-group';
import Modals from './uikit/modal';
import Navbars from './uikit/navbar';
import Navs from './uikit/nav';
import OffCanvass from './uikit/off-canvas';
import Paginations from './uikit/pagination';
import Popovers from './uikit/popovers';
import Scrollspys from './uikit/scrollspy';
import Spinnerss from './uikit/spinner';
import Toasts from './uikit/toast';
import Tooltips from './uikit/tooltip';
import Progresss from './uikit/progress';
//form
import DisabledForms from './uikit/disabled-form';
import AFormControls from './uikit/alternate-form-control';
import Sizings from './uikit/sizing';
import InputGroups from './uikit/input-group';
import FloatingLables from './uikit/floating-lable';
import AFloatingLables from './uikit/alternate-floating-lable';
import ToggleBtns from './uikit/toggle-btn';
import Validations from './uikit/validation';
import Overview from './uikit/form-overview';

// content
import Typographys from './uikit/typography';
import Images from './uikit/image';
import Figures from './uikit/figure';
import Tables from './uikit/table';

//img
import topImage from '../assets/images/dashboard/top-image.jpg';
import github from '../assets/images/brands/23.png';

//prism
import '../../node_modules/prismjs/prism';
import '../../node_modules/prismjs/themes/prism-okaidia.css';

// SliderTab
import SliderTab from '../plugins/slider-tabs';

// Import selectors & action from setting store
import * as SettingSelector from '../store/setting/selectors';

// Redux Selector / Action
import { useSelector } from 'react-redux';
import { useAppSelector } from '../store';

const Index = () => {
  const appName = useSelector(SettingSelector.app_name);
  // read user info from redux store
  const userState = useAppSelector((state) => state.user);

  // collapse
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  useEffect(() => {
    return () => {
      setTimeout(() => {
        Array.from(document.querySelectorAll('[data-toggle="slider-tab"]'), (elem) => {
          return new SliderTab(elem);
        });
      }, 100);
    };
  });

  return (
    <Fragment>
      <span className="uisheet screen-darken"></span>
      <div
        className="header"
        style={{
          background: `url(${topImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
          position: 'relative',
        }}
      >
        <div className="main-img">
          <div className="container">
            <Image src="/kryptopay-icon.svg" width={160} />
            <h1 className="my-4">
              <span data-setting="app_name">{appName}</span>
            </h1>
            <h4 className="text-white mb-5">
              Production ready <b>Payment Gateway</b>
            </h4>
            {/* <div className="d-flex justify-content-center align-items-center">
              <div>
                <Link className="btn btn-light bg-white d-flex" to="/dashboard" target="_black">
                  <svg
                    width="22"
                    height="22"
                    className="me-1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                    ></path>
                  </svg>
                  Dashboard Demo
                </Link>
              </div>
              <div className="ms-3">
                <Button
                  bsPrefix=" btn btn-light bg-white d-flex"
                  target="_blank"
                  href="https://github.com/iqonic-design/hope-ui-react-dashboard"
                >
                  <img src={github} width="24px" height="24px" alt="github" />
                  <span className="text-danger mx-2 fw-bold">STAR US</span>
                  <span>ON GITHUB</span>
                </Button>
              </div>
            </div> */}
          </div>
        </div>
        <Container>
          <Nav className="rounded  navbar navbar-expand-lg navbar-light top-1">
            <Container>
              <Navbar.Brand href="/" className="mx-2 d-flex align-items-center">
                <Image src="/kryptopay-icon.svg" width={30} />
                <h5 className="logo-title mx-3">{appName}</h5>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setOpen3(!open3)} />
              <Navbar.Collapse id="basic-navbar-nav" in={open3}>
                <ul className="mb-2 navbar-nav ms-auto mb-lg-0 d-flex align-items-start">
                  {/* <Nav.Item as="li">
                    <Nav.Link
                      aria-current="page"
                      href="https://templates.iqonic.design/hope-ui/pro/documentation/react"
                      target="_blank"
                    >
                      Documentation
                    </Nav.Link>
                  </Nav.Item> */}
                  {/* <li className="nav-item me-3">
                    <Nav.Link
                      aria-current="page"
                      href="https://templates.iqonic.design/hope-ui/pro/documentation/react/changelog"
                      target="_blank"
                    >
                      Change Log
                    </Nav.Link>
                  </li> */}

                  {userState.token && (
                    <>
                      <Nav.Item as="li" className="me-3 mb-2 mb-sm-0 px-2">
                        <Nav.Link href="/profile">Account</Nav.Link>
                      </Nav.Item>
                    </>
                  )}
                  {!userState.token && (
                    <>
                      <Nav.Item as="li" className="me-3 mb-2 mb-sm-0 px-2">
                        <Nav.Link href="/auth/sign-in">Sign in</Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li" className="me-3 mb-2 mb-sm-0 px-2">
                        <Nav.Link href="/auth/sign-up">Sign up</Nav.Link>
                      </Nav.Item>
                    </>
                  )}
                  {/* <Nav.Item as="li">
                    <Button
                      variant="success d-flex align-items-center gap-2"
                      aria-current="page"
                      href="https://iqonic.design/item/hope-ui-pro/item-checkout/?coupon_code=DROPBY20"
                      target="_blank"
                    >
                      <svg
                        className="icon-22"
                        width="22"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.4"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.91064 20.5886C5.91064 19.7486 6.59064 19.0686 7.43064 19.0686C8.26064 19.0686 8.94064 19.7486 8.94064 20.5886C8.94064 21.4186 8.26064 22.0986 7.43064 22.0986C6.59064 22.0986 5.91064 21.4186 5.91064 20.5886ZM17.1606 20.5886C17.1606 19.7486 17.8406 19.0686 18.6806 19.0686C19.5106 19.0686 20.1906 19.7486 20.1906 20.5886C20.1906 21.4186 19.5106 22.0986 18.6806 22.0986C17.8406 22.0986 17.1606 21.4186 17.1606 20.5886Z"
                          fill="currentColor"
                        ></path>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M20.1907 6.34909C20.8007 6.34909 21.2007 6.55909 21.6007 7.01909C22.0007 7.47909 22.0707 8.13909 21.9807 8.73809L21.0307 15.2981C20.8507 16.5591 19.7707 17.4881 18.5007 17.4881H7.59074C6.26074 17.4881 5.16074 16.4681 5.05074 15.1491L4.13074 4.24809L2.62074 3.98809C2.22074 3.91809 1.94074 3.52809 2.01074 3.12809C2.08074 2.71809 2.47074 2.44809 2.88074 2.50809L5.26574 2.86809C5.60574 2.92909 5.85574 3.20809 5.88574 3.54809L6.07574 5.78809C6.10574 6.10909 6.36574 6.34909 6.68574 6.34909H20.1907ZM14.1307 11.5481H16.9007C17.3207 11.5481 17.6507 11.2081 17.6507 10.7981C17.6507 10.3781 17.3207 10.0481 16.9007 10.0481H14.1307C13.7107 10.0481 13.3807 10.3781 13.3807 10.7981C13.3807 11.2081 13.7107 11.5481 14.1307 11.5481Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      Buy Now
                    </Button>
                  </Nav.Item> */}
                </ul>
              </Navbar.Collapse>
            </Container>
          </Nav>
        </Container>
      </div>
      {/* <div className=" body-class-1 container">
        <aside
          className="mobile-offcanvas bd-aside card iq-document-card sticky-xl-top text-muted align-self-start mb-5 mt-n5"
          id="left-side-bar"
        >
          <div className="offcanvas-header p-0">
            <button className="btn-close float-end"></button>
          </div>
          <h2 className="h6 pb-2 border-bottom">On this page</h2>
          <div className="small" id="elements-section">
            <ul className="list-unstyled mb-0">
              <li className="mt-2">
                <Button
                  variant=" d-inline-flex align-items-center "
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                >
                  <i className="right-icon me-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon-18"
                      width="18"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                  </i>
                  Components
                </Button>
                <Collapse in={open}>
                  <ul className="list-unstyled ps-3 elem-list" id="components-collapse" to="#components">
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#accordion">
                        Accordion
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#alerts">
                        Alerts
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#badge">
                        Badge
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#breadcrumb">
                        Breadcrumb
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#buttons">
                        Buttons
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#button-group">
                        Button Group
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#calendar">
                        Calendar
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#card">
                        Card
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#carousel">
                        Carousel
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#dropdowns">
                        Dropdowns
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#list-group">
                        List Group
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#modal">
                        Modal
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#navs">
                        Navs
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#navbar">
                        Navbar
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#off-canvas">
                        Off Canvas
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#pagination">
                        Pagination
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#popovers">
                        Popovers
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-item-center rounded" href="#ribbon">
                        Ribbon
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#progress">
                        Progress
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#scrollspy">
                        Scrollspy
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#spinners">
                        Spinners
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#toasts">
                        Toasts
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#tooltips">
                        Tooltips
                      </Nav.Link>
                    </li>
                  </ul>
                </Collapse>
              </li>
              <li className="my-2">
                <Button
                  variant=" d-inline-flex align-items-center "
                  onClick={() => setOpen1(!open1)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open1}
                >
                  <i className="right-icon me-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon-18"
                      width="18"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                  </i>
                  Forms
                </Button>
                <Collapse in={open1}>
                  <ul className="list-unstyled ps-3 " id="forms-collapse" to="#forms">
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#overview">
                        Overview
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#disabled-forms">
                        Disabled Forms
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#sizing">
                        Sizing
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#input-group">
                        Input Group
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#a-form-control">
                        Alertnate Input
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#floating-labels">
                        Floating Labels
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#a-floating-labels">
                        Alertnate Float Labels
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#toggle-btn">
                        Toggle Button
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#validation">
                        Validation
                      </Nav.Link>
                    </li>
                  </ul>
                </Collapse>
              </li>
              <li className="mb-2">
                <Button
                  variant=" d-inline-flex align-items-center "
                  onClick={() => setOpen2(!open2)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open2}
                >
                  <i className="right-icon me-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon-18"
                      width="18"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                  </i>
                  Contents
                </Button>
                <Collapse in={open2}>
                  <ul className="list-unstyled ps-3 " id="contents-collapse" to="#content">
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#typography">
                        Typography
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#images">
                        Images
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#tables">
                        Tables
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link className="d-inline-flex align-items-center rounded" href="#figures">
                        Figures
                      </Nav.Link>
                    </li>
                  </ul>
                </Collapse>
              </li>
            </ul>
          </div>
        </aside>
        <div className="bd-cheatsheet container-fluid bg-trasprent mt-n5">
          <section id="components">
            <div className="iq-side-content sticky-xl-top">
              <Card className="">
                <Card.Body className="">
                  <h4 className="fw-bold">Components</h4>
                </Card.Body>
              </Card>
            </div>
            <Accordions />
            <Alerts />
            <Badges />
            <Breadcrumbs />
            <Buttons />
            <ButtonGroups />
            <Calenders />
            <Cards />
            <Carousels />
            <DropDowns />
            <ListGroups />
            <Modals />
            <Navs />
            <Navbars />
            <OffCanvass />
            <Paginations />
            <Popovers />
            <Progresss />
            <Scrollspys />
            <Spinnerss />
            <Toasts />
            <Tooltips />
          </section>
          <section id="forms">
            <div className="iq-side-content sticky-xl-top">
              <Card className="">
                <Card.Body className="">
                  <h4 className="fw-bold">Forms</h4>
                </Card.Body>
              </Card>
            </div>
            <Overview />
            <DisabledForms />
            <Sizings />
            <InputGroups />
            <AFormControls />
            <FloatingLables />
            <AFloatingLables />
            <ToggleBtns />
            <Validations />
          </section>
          <section id="content">
            <div className="iq-side-content sticky-xl-top">
              <Card className="">
                <Card.Body className="">
                  <h4 className="fw-bold">Contents</h4>
                </Card.Body>
              </Card>
            </div>
            <Typographys />
            <Images />
            <Tables />
            <Figures />
          </section>
        </div>
      </div> */}
      {/* <div id="back-to-top" style={{ display: 'none' }}>
        <Button size="xs" variant="primary  p-0 position-fixed top" href="#top">
          <svg width="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5 15.5L12 8.5L19 15.5"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </Button>
      </div> */}
    </Fragment>
  );
};

export default Index;
