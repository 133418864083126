import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

//router
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
//store
import { Provider } from 'react-redux';
//reducer
import { store } from './store';

import Index from './views/index';
import { IndexRouters } from './router';
import { SimpleRouter } from './router/simple-router';
import { DefaultRouter } from './router/default-router';
import { AdminRouter } from './router/admin-router';
import Error404 from './views/dashboard/errors/error404';
import { AppRouter } from './router/app-router';

import { ToastContainer } from 'react-toastify';
import { InitializeUser, SyncUser } from './helpers';

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Index />,
    },
    // ...DefaultRouter,
    ...IndexRouters,
    ...SimpleRouter,
    ...AdminRouter,
    ...AppRouter,
    {
      path: '*',
      element: <Error404 />,
    },
  ],
  { basename: process.env.REACT_APP_PUBLIC_URL }
);

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <Provider store={store}>
    <App>
      <InitializeUser />
      <SyncUser />
      <RouterProvider router={router}></RouterProvider>
      <ToastContainer theme="colored" />
    </App>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
