import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import { Toaster, withAuth } from '../../helpers';
import { addWalletUrl, delWalletUrl, profileUrl, urlWalletUrl } from '../../consts';
import { useApiRequest } from '../../hooks';
import { useAppSelector } from '../../store';
import Skeleton from 'react-loading-skeleton';

const ProfileElement = () => {
  // read token from the redux store
  const userState = useAppSelector((state) => state.user);

  // new wallet address
  const [addr, setAddr] = useState('');
  const [pureAddr, setPureAddr] = useState('');
  const [addrs, setAddrs] = useState([]);
  const [pureAddrs, setPureAddrs] = useState([]);
  const [rootUrl, setRootUrl] = useState('');
  const [hookUrl, setHookUrl] = useState('');
  const [isChanged, setChanged] = useState(false);

  // get profile request function
  const { response, error, loading, sendRequest } = useApiRequest({
    endpoint: profileUrl,
    method: 'GET',
    headers: {
      authorization: `Bearer ${userState.token}`,
      Accept: 'application/json',
    },
  });

  // send wallet addition request
  const {
    response: addResponse,
    error: addError,
    loading: addLoading,
    sendRequest: sendAddRequest,
  } = useApiRequest({
    endpoint: addWalletUrl,
    method: 'POST',
    headers: {
      authorization: `Bearer ${userState.token}`,
      Accept: 'application/json',
    },
  });

  // send wallet deletion request
  const {
    response: delResponse,
    error: delError,
    loading: delLoading,
    sendRequest: sendDelRequest,
  } = useApiRequest({
    endpoint: delWalletUrl,
    method: 'DELETE',
    headers: {
      authorization: `Bearer ${userState.token}`,
      Accept: 'application/json',
    },
  });

  // send URLs request
  const {
    response: urlResponse,
    error: urlError,
    loading: urlLoading,
    sendRequest: sendUrlRequest,
  } = useApiRequest({
    endpoint: urlWalletUrl,
    method: 'POST',
    headers: {
      authorization: `Bearer ${userState.token}`,
      Accept: 'application/json',
    },
    data: {
      rootUrl,
      hookUrl,
    },
  });

  // info response
  useEffect(() => {
    response && response.data && response.data.txcAddresses && setAddrs(response.data.txcAddresses);
    response && response.data && response.data.pureAddresses && setPureAddrs(response.data.pureAddresses);
    response && response.data && setRootUrl(response.data.siteBaseUrl ?? '');
    response && response.data && setHookUrl(response.data.webHookUrl ?? '');
  }, [response]);

  // if error while getting info
  useEffect(() => {
    if (error) {
      Toaster.error(error.message);
    }
  }, [error]);

  // add response
  useEffect(() => {
    if (addResponse && addResponse.data) {
      if (addResponse.data.crypto === 'txc') setAddrs(addResponse.data.addresses);
      else if (addResponse.data.crypto === 'pure') setPureAddrs(addResponse.data.addresses);

      if (addResponse.data.crypto === 'txc') setAddr('');
      else if (addResponse.data.crypto === 'pure') setPureAddr('');

      Toaster.success(addResponse.message);
    }
  }, [addResponse]);

  // add error
  useEffect(() => {
    addError && Toaster.error(addError.message);
  }, [addError]);

  // del response
  useEffect(() => {
    if (delResponse && delResponse.data && delResponse.data.addresses) {
      if (delResponse.data.type === 'txc') setAddrs(delResponse.data.addresses);
      else if (delResponse.data.type === 'pure') setPureAddrs(delResponse.data.addresses);
      Toaster.success(delResponse.message);
    }
  }, [delResponse]);

  // del error
  useEffect(() => {
    delError && Toaster.error(delError.message);
  }, [delError]);

  // url response
  useEffect(() => {
    if (urlResponse) {
      Toaster.success(urlResponse.message);
      setChanged(false);
    }
  }, [urlResponse]);

  // url error
  useEffect(() => {
    urlError && Toaster.error(urlError.message);
  }, [urlError]);

  // send request on load
  useEffect(() => {
    userState.token && sendRequest();
  }, [userState]);

  return (
    <>
      <div>
        <Row>
          <Col sm="12" lg="6">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">API Key</h4>
                </div>
              </Card.Header>
              <Card.Body>
                <p>This API key can be used to send requests to the payment gateway. This key is public.</p>
                <Row>
                  <Col>
                    {loading && <Skeleton height={24} />}
                    {!loading && <code className="fs-5">{response?.data?.apikey}</code>}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Secret</h4>
                </div>
              </Card.Header>
              <Card.Body>
                <p>
                  This secret can be used for calling webhook from the payment gateway. DO NOT share this with anyone
                  else.
                </p>
                <Row>
                  <Col>
                    {loading && <Skeleton height={24} />}
                    {!loading && <code className="fs-5">{response?.data?.secret}</code>}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="12" lg="6">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">URLs</h4>
                </div>
              </Card.Header>
              <Card.Body>
                <p>You can configure your store's URLs here to enable their use in payment integration.</p>
                <Form>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="urlRoot">Store Root Url</Form.Label>
                    {loading && <Skeleton height={40} />}
                    {!loading && (
                      <Form.Control
                        type="text"
                        id="urlRoot"
                        placeholder="https://example.com"
                        value={rootUrl}
                        onChange={(e) => {
                          setRootUrl(e.target.value);
                          setChanged(true);
                        }}
                      />
                    )}
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="urlWebhook">Store Webhook Url</Form.Label>
                    {loading && <Skeleton height={40} />}
                    {!loading && (
                      <Form.Control
                        type="text"
                        id="urlWebhook"
                        placeholder="https://example.com/webhook"
                        value={hookUrl}
                        onChange={(e) => {
                          setHookUrl(e.target.value);
                          setChanged(true);
                        }}
                      />
                    )}
                  </Form.Group>
                  <Button
                    type="button"
                    variant="btn btn-primary"
                    disabled={urlLoading || !isChanged}
                    onClick={async () => await sendUrlRequest()}
                  >
                    {!urlLoading ? 'Save' : 'Saving...'}
                  </Button>{' '}
                </Form>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">TXC Wallet ZPub</h4>
                </div>
              </Card.Header>
              <Card.Body>
                <p>
                  Please register one or more TXC wallet addresses to facilitate the receipt of TXC token payments from
                  users.
                </p>
                {loading && (
                  <>
                    <Skeleton height={40} />
                    <Skeleton height={40} />
                  </>
                )}
                {!loading &&
                  addrs &&
                  addrs.map((add) => (
                    <Form key={add} className="comment-text d-flex align-items-center mt-3" action="">
                      <Form.Control
                        type="text"
                        className="rounded pe-4 overflow-hidden text-truncate"
                        defaultValue={add}
                        disabled
                      />
                      <div
                        className="comment-attagement d-flex text-danger"
                        style={{ cursor: 'pointer' }}
                        onClick={async (e) => {
                          !delLoading &&
                            (await sendDelRequest({
                              addr: e.target.closest('form').firstElementChild.value,
                              type: 'txc',
                            }));
                        }}
                      >
                        <svg
                          className="icon-24"
                          width="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.4"
                            d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </div>
                    </Form>
                  ))}
                <Form className="comment-text d-flex align-items-center mt-3" action="">
                  <Form.Control
                    type="text"
                    className="rounded border-highlight pe-5"
                    placeholder="New wallet address"
                    value={addr}
                    onChange={(e) => setAddr(e.target.value)}
                  />
                  <div
                    className="comment-attagement d-flex text-success"
                    style={{ cursor: 'pointer' }}
                    onClick={async () => {
                      addr !== '' && !addLoading && (await sendAddRequest({ crypto: 'txc', zPub: addr }));
                    }}
                  >
                    {addLoading && (
                      <svg
                        className="icon-24"
                        width="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.4"
                          d="M22 12C22 17.524 17.523 22 12 22C6.477 22 2 17.524 2 12C2 6.478 6.477 2 12 2C17.523 2 22 6.478 22 12Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M15.5739 15.8145C15.4429 15.8145 15.3109 15.7805 15.1899 15.7095L11.2639 13.3675C11.0379 13.2315 10.8989 12.9865 10.8989 12.7225V7.67554C10.8989 7.26154 11.2349 6.92554 11.6489 6.92554C12.0629 6.92554 12.3989 7.26154 12.3989 7.67554V12.2965L15.9589 14.4195C16.3139 14.6325 16.4309 15.0925 16.2189 15.4485C16.0779 15.6835 15.8289 15.8145 15.5739 15.8145Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    )}
                    {!addLoading && (
                      <svg
                        className="icon-24"
                        width="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.4"
                          d="M16.6667 2H7.33333C3.92889 2 2 3.92889 2 7.33333V16.6667C2 20.0622 3.92 22 7.33333 22H16.6667C20.0711 22 22 20.0622 22 16.6667V7.33333C22 3.92889 20.0711 2 16.6667 2Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M15.3205 12.7083H12.7495V15.257C12.7495 15.6673 12.4139 16 12 16C11.5861 16 11.2505 15.6673 11.2505 15.257V12.7083H8.67955C8.29342 12.6687 8 12.3461 8 11.9613C8 11.5765 8.29342 11.2539 8.67955 11.2143H11.2424V8.67365C11.2824 8.29088 11.6078 8 11.996 8C12.3842 8 12.7095 8.29088 12.7495 8.67365V11.2143H15.3205C15.7066 11.2539 16 11.5765 16 11.9613C16 12.3461 15.7066 12.6687 15.3205 12.7083Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    )}
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default withAuth(ProfileElement);
