import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Container } from 'react-bootstrap';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import { Toaster, withAuth } from '../../helpers';
import { chainListUrl, transUrl } from '../../consts';
import { useAppSelector } from '../../store';
import { useApiRequest } from '../../hooks';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const pageIndexes = (current, total, per) => {
  if (total === 0) return [];

  const start = current >= 3 ? current - 2 : 1;
  const end = current <= Math.ceil(total / per) - 2 ? current + 2 : Math.ceil(total / per);

  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
};

const TransactionsElement = () => {
  // read token from the redux store
  const userState = useAppSelector((state) => state.user);

  const [chains, setChains] = useState([]);
  const [chain, setChain] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const perPage = 10;

  // send chains list request
  const {
    response: chainListResponse,
    error: chainListError,
    loading: chainListLoading,
    sendRequest: sendChainListRequest,
  } = useApiRequest({
    endpoint: chainListUrl,
    method: 'GET',
  });

  // send address list request
  const {
    response: txsResponse,
    error: txsError,
    loading: txsLoading,
    sendRequest: sendTxsRequest,
  } = useApiRequest({
    endpoint: transUrl,
    method: 'GET',
    headers: {
      authorization: `Bearer ${userState.token}`,
      Accept: 'application/json',
    },
    params: {
      perPage,
    },
  });

  // on load send chain list request
  useEffect(() => {
    sendChainListRequest();
  }, []);

  useEffect(() => {
    chainListError && Toaster.error(chainListError.message);
  }, [chainListError]);

  useEffect(() => {
    chainListResponse && setChains(chainListResponse.data.chains);
  }, [chainListResponse]);

  // send tx list request
  useEffect(() => {
    userState && userState.token && chain.shortName && sendTxsRequest({}, { currentPage, chain: chain.shortName });
    userState && userState.token && !chain.shortName && sendTxsRequest({}, { currentPage });
  }, [userState, currentPage, chain]);

  // response
  useEffect(() => {
    txsResponse && txsResponse.data && setTotalCount(txsResponse.data.totalCount);
    txsResponse && txsResponse.data && setData(txsResponse.data.txs);
  }, [txsResponse]);

  // if error while getting data
  useEffect(() => {
    if (txsError) {
      Toaster.error(txsError.message);
    }
  }, [txsError]);

  return (
    <Container fluid>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header>
              <div className="header-title w-100 d-flex justify-content-between align-items-center">
                <h4 className="card-title">Transactions</h4>
                <div className="d-flex align-items-center">
                  <div className="dropdown">
                    <button
                      className="btn btn-gray dropdown-toggle me-2"
                      type="button"
                      id="dropdownMenuButtonSM"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {chain.chainName && `${chain.chainName}(${chain.shortName})`}
                      {!chain.chainName && `All Chains`}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtonSM">
                      <li>
                        <h6 className="dropdown-header">Select a chain</h6>
                      </li>
                      {chains && (
                        <>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => {
                                setChain({});
                              }}
                            >
                              All Chains
                            </a>
                          </li>
                          {chains.map((item) => (
                            <li key={item.shortName}>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setChain(item);
                                }}
                              >
                                {`${item.chainName}(${item.shortName})`}
                              </a>
                            </li>
                          ))}
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <p>Manages the addresses of your store.</p>
              <div className="table-responsive my-3">
                <Table responsive id="datatable" className="" data-toggle="data-table">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Tx Hash</th>
                      <th>Address</th>
                      <th>Amount</th>
                      <th>Fiat (USD)</th>
                      <th>Confirmed</th>
                      <th>Informed</th>
                      <th>Created</th>
                      <th>Info</th>
                    </tr>
                  </thead>
                  <tbody>
                    {txsLoading && (
                      <tr>
                        <td colSpan={9} className="text-center">
                          Loading transactions...
                        </td>
                      </tr>
                    )}
                    {!txsLoading && totalCount === 0 && (
                      <tr>
                        <td colSpan={9} className="text-center">
                          No transactions
                        </td>
                      </tr>
                    )}
                    {!txsLoading &&
                      totalCount > 0 &&
                      data &&
                      data.map((item, i) => (
                        <tr key={i}>
                          <td>{i + 1 + (currentPage - 1) * perPage}</td>
                          <td style={{ fontFamily: 'monospace' }}>{`${item.txhash.substring(
                            0,
                            8
                          )}...${item.txhash.substr(-8)}`}</td>
                          <td style={{ fontFamily: 'monospace' }}>{`${item.address_info.address.substring(
                            0,
                            8
                          )}...${item.address_info.address.substr(-8)}`}</td>
                          <td>{`${item.amount} ${item.address_info.chain.toUpperCase()}`}</td>
                          <td>{`${item.fiat.toFixed(4)}`}</td>
                          <td>
                            {item.isConfirmed && (
                              <FontAwesomeIcon icon={faCircleCheck} className="text-success pe-2" size="xl" />
                            )}
                            {!item.isConfirmed && (
                              <FontAwesomeIcon icon={faCircleXmark} className="text-danger pe-2" size="xl" />
                            )}
                          </td>
                          <td>
                            {item.isInformed && (
                              <FontAwesomeIcon icon={faCircleCheck} className="text-success pe-2" size="xl" />
                            )}
                            {!item.isInformed && (
                              <FontAwesomeIcon icon={faCircleXmark} className="text-danger pe-2" size="xl" />
                            )}
                          </td>
                          <td>{new Date(item.createdAt).toLocaleString()}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="badge bg-warning me-2" style={{ width: '50px' }}>
                                User
                              </span>
                              {`${item.extra_info.uname}(ID #${item.extra_info.uid}, ${item.extra_info.umail})`}
                            </div>
                            <div className="d-flex align-items-center">
                              <span className="badge bg-warning me-2" style={{ width: '50px' }}>
                                Order
                              </span>
                              {`#${item.extra_info.oid}`}
                            </div>
                            <div className="d-flex align-items-center">
                              <span className="badge bg-warning me-2" style={{ width: '50px' }}>
                                Price
                              </span>
                              {`${(item.extra_info.price / 100).toFixed(2)}`}
                            </div>
                          </td>
                          {/* <td className="text-center">
                            {item.isVerified && (
                              <svg
                                className="icon-24"
                                style={{ color: 'mediumseagreen' }}
                                width="24"
                                viewBox="0 0 24 24"
                                fill="green"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M11.7281 21.9137C11.8388 21.9715 11.9627 22.0009 12.0865 22C12.2103 21.999 12.3331 21.9686 12.4449 21.9097L16.0128 20.0025C17.0245 19.4631 17.8168 18.8601 18.435 18.1579C19.779 16.6282 20.5129 14.6758 20.4998 12.6626L20.4575 6.02198C20.4535 5.25711 19.9511 4.57461 19.2082 4.32652L12.5707 2.09956C12.1711 1.96424 11.7331 1.96718 11.3405 2.10643L4.72824 4.41281C3.9893 4.67071 3.496 5.35811 3.50002 6.12397L3.54231 12.7597C3.5554 14.7758 4.31448 16.7194 5.68062 18.2335C6.3048 18.9258 7.10415 19.52 8.12699 20.0505L11.7281 21.9137ZM10.7836 14.1089C10.9326 14.2521 11.1259 14.3227 11.3192 14.3207C11.5125 14.3198 11.7047 14.2472 11.8517 14.1021L15.7508 10.2581C16.0438 9.96882 16.0408 9.50401 15.7448 9.21866C15.4478 8.9333 14.9696 8.93526 14.6766 9.22454L11.3081 12.5449L9.92885 11.2191C9.63186 10.9337 9.15467 10.9367 8.8607 11.226C8.56774 11.5152 8.57076 11.98 8.86775 12.2654L10.7836 14.1089Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            )}
                            {!item.isVerified && (
                              <svg
                                className="icon-24"
                                style={{ color: 'red' }}
                                width="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M19.2013 4.32867C19.9461 4.57382 20.4493 5.26122 20.4493 6.02608L20.4997 12.6637C20.5097 14.674 19.775 16.6253 18.4263 18.1561C17.8123 18.8621 17.0172 19.4603 16.0107 19.9996L12.4377 21.9117C12.327 21.9706 12.2062 22 12.0855 22C11.9647 22 11.8338 21.9706 11.7231 21.9117L8.11991 20.0486C7.10336 19.5191 6.30824 18.9307 5.68422 18.2345C4.3154 16.7244 3.55047 14.773 3.54041 12.7628L3.50015 6.12316C3.49008 5.3583 3.99333 4.67286 4.72806 4.41693L11.3407 2.1037C11.7332 1.96543 12.166 1.96543 12.5686 2.1037L19.2013 4.32867ZM14.4205 14.0866C14.7124 13.8022 14.7124 13.3315 14.4205 13.0472L13.0617 11.7224L14.4205 10.3995C14.7124 10.1152 14.7124 9.65429 14.4205 9.35913C14.1286 9.07476 13.6455 9.07476 13.3536 9.35913L11.9949 10.6839L10.6361 9.35913C10.3442 9.07476 9.87119 9.07476 9.56924 9.35913C9.27736 9.65429 9.27736 10.1152 9.56924 10.3995L10.928 11.7224L9.56924 13.0472C9.27736 13.3315 9.27736 13.8022 9.56924 14.0866C9.72022 14.2337 9.91145 14.3013 10.1027 14.3013C10.304 14.3013 10.4952 14.2337 10.6361 14.0866L11.9949 12.7628L13.3536 14.0866C13.5046 14.2337 13.6958 14.3013 13.8871 14.3013C14.0783 14.3013 14.2796 14.2337 14.4205 14.0866Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            )}
                          </td>
                          <td className="text-center">
                            {item.isInformed && (
                              <svg
                                className="icon-24"
                                style={{ color: 'mediumseagreen' }}
                                width="24"
                                viewBox="0 0 24 24"
                                fill="green"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M11.7281 21.9137C11.8388 21.9715 11.9627 22.0009 12.0865 22C12.2103 21.999 12.3331 21.9686 12.4449 21.9097L16.0128 20.0025C17.0245 19.4631 17.8168 18.8601 18.435 18.1579C19.779 16.6282 20.5129 14.6758 20.4998 12.6626L20.4575 6.02198C20.4535 5.25711 19.9511 4.57461 19.2082 4.32652L12.5707 2.09956C12.1711 1.96424 11.7331 1.96718 11.3405 2.10643L4.72824 4.41281C3.9893 4.67071 3.496 5.35811 3.50002 6.12397L3.54231 12.7597C3.5554 14.7758 4.31448 16.7194 5.68062 18.2335C6.3048 18.9258 7.10415 19.52 8.12699 20.0505L11.7281 21.9137ZM10.7836 14.1089C10.9326 14.2521 11.1259 14.3227 11.3192 14.3207C11.5125 14.3198 11.7047 14.2472 11.8517 14.1021L15.7508 10.2581C16.0438 9.96882 16.0408 9.50401 15.7448 9.21866C15.4478 8.9333 14.9696 8.93526 14.6766 9.22454L11.3081 12.5449L9.92885 11.2191C9.63186 10.9337 9.15467 10.9367 8.8607 11.226C8.56774 11.5152 8.57076 11.98 8.86775 12.2654L10.7836 14.1089Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            )}
                            {!item.isInformed && (
                              <svg
                                className="icon-24"
                                style={{ color: 'red' }}
                                width="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M19.2013 4.32867C19.9461 4.57382 20.4493 5.26122 20.4493 6.02608L20.4997 12.6637C20.5097 14.674 19.775 16.6253 18.4263 18.1561C17.8123 18.8621 17.0172 19.4603 16.0107 19.9996L12.4377 21.9117C12.327 21.9706 12.2062 22 12.0855 22C11.9647 22 11.8338 21.9706 11.7231 21.9117L8.11991 20.0486C7.10336 19.5191 6.30824 18.9307 5.68422 18.2345C4.3154 16.7244 3.55047 14.773 3.54041 12.7628L3.50015 6.12316C3.49008 5.3583 3.99333 4.67286 4.72806 4.41693L11.3407 2.1037C11.7332 1.96543 12.166 1.96543 12.5686 2.1037L19.2013 4.32867ZM14.4205 14.0866C14.7124 13.8022 14.7124 13.3315 14.4205 13.0472L13.0617 11.7224L14.4205 10.3995C14.7124 10.1152 14.7124 9.65429 14.4205 9.35913C14.1286 9.07476 13.6455 9.07476 13.3536 9.35913L11.9949 10.6839L10.6361 9.35913C10.3442 9.07476 9.87119 9.07476 9.56924 9.35913C9.27736 9.65429 9.27736 10.1152 9.56924 10.3995L10.928 11.7224L9.56924 13.0472C9.27736 13.3315 9.27736 13.8022 9.56924 14.0866C9.72022 14.2337 9.91145 14.3013 10.1027 14.3013C10.304 14.3013 10.4952 14.2337 10.6361 14.0866L11.9949 12.7628L13.3536 14.0866C13.5046 14.2337 13.6958 14.3013 13.8871 14.3013C14.0783 14.3013 14.2796 14.2337 14.4205 14.0866Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            )}
                          </td>
                          <td>{item.crypto.toUpperCase()}</td>
                          <td>{item.amount}</td>
                          <td>{(item.fiat / 100).toFixed(2)}</td>
                          <td>{new Date(item.createdAt).toLocaleString()}</td> */}
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-between align-items-center mx-1 mt-2">
                  <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                    {totalCount === 0 && 'No transactions'}
                    {totalCount > 0 &&
                      `Showing ${(currentPage - 1) * perPage + 1} to ${Math.min(
                        currentPage * perPage,
                        totalCount
                      )} of ${totalCount} entries`}
                  </div>
                  <div className="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                    <ul className="pagination mb-0">
                      <li
                        className={`paginate_button page-item previous ${currentPage === 1 ? 'disabled' : ''}`}
                        id="datatable_previous"
                      >
                        <Link
                          to="#"
                          aria-controls="datatable"
                          aria-disabled="true"
                          data-dt-idx="previous"
                          tabIndex="0"
                          className="page-link"
                          onClick={() => setCurrentPage(currentPage - 1)}
                        >
                          Prev
                        </Link>
                      </li>

                      {pageIndexes(currentPage, totalCount, perPage).map((ele) => (
                        <li className={`paginate_button page-item ${currentPage === ele ? 'active' : ''}`} key={ele}>
                          <Link
                            aria-controls="datatable"
                            aria-current="page"
                            data-dt-idx={ele}
                            tabIndex="0"
                            className="page-link"
                            onClick={() => setCurrentPage(ele)}
                          >
                            {ele}
                          </Link>
                        </li>
                      ))}
                      <li
                        className={`paginate_button page-item next ${
                          currentPage === Math.ceil(totalCount / perPage) ? 'disabled' : ''
                        }`}
                        id="datatable_next"
                      >
                        <Link
                          to="#"
                          aria-controls="datatable"
                          data-dt-idx="next"
                          tabIndex="0"
                          className="page-link"
                          onClick={() => setCurrentPage(currentPage + 1)}
                        >
                          Next
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default withAuth(TransactionsElement);
