import Checkout from '../views/app/checkout';
import PreCheckout from '../views/app/preCheckout';
import CheckoutConfirm from '../views/app/checkoutConfirm';

export const AppRouter = [
  {
    path: '/app',
    children: [
      {
        path: 'checkout/:apikey/:crypto',
        element: <Checkout />,
      },
      {
        path: 'checkout/:apikey',
        element: <PreCheckout />,
      },
      {
        path: 'checkout-confirm/:apikey/:oid',
        element: <CheckoutConfirm />,
      },
    ],
  },
];
