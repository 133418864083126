import React, { useState, useEffect, Fragment } from 'react';
import { Nav, Navbar, Container, Image, Row, Col, Table } from 'react-bootstrap';

//img
import topImage from '../../assets/images/dashboard/top-image.jpg';

//prism
import 'prismjs';
import '../../../node_modules/prismjs/themes/prism-okaidia.css';

// SliderTab
import SliderTab from '../../plugins/slider-tabs';

// Import selectors & action from setting store
import * as SettingSelector from '../../store/setting/selectors';

// Redux Selector / Action
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { orderInfoUrl } from '../../consts';
import { useApiRequest } from '../../hooks';

const CheckoutConfirmPage = () => {
  const appName = useSelector(SettingSelector.app_name);

  // get URL params
  const { apikey, oid } = useParams();

  // get query params
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const refer = queryParams.get('refer');

  const [height, setHeight] = useState('auto');
  const [info, setInfo] = useState({});

  useEffect(() => {
    const handleResize = () => {
      const parentHeight = document.documentElement.scrollHeight; // or another parent element's height
      const viewportHeight = window.innerHeight;

      // Set height to the maximum of parent height and viewport height
      setHeight(Math.max(parentHeight, viewportHeight));
    };

    // Initial resize calculation
    handleResize();

    // Event listener for window resizing
    window.addEventListener('resize', handleResize);

    // Cleanup on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // send address request
  const { response, error, loading, sendRequest } = useApiRequest({
    endpoint: orderInfoUrl,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'x-api-key': apikey,
    },
    params: {
      oid,
    },
  });

  // send request for info
  useEffect(() => {
    apikey && sendRequest();
  }, [apikey, oid]);

  // info response
  useEffect(() => {
    response && setInfo(response.data ?? {});
  }, [response]);

  // info error
  useEffect(() => {
    error && setInfo({});
  }, [error]);

  // slider
  useEffect(() => {
    return () => {
      setTimeout(() => {
        Array.from(document.querySelectorAll('[data-toggle="slider-tab"]'), (elem) => {
          return new SliderTab(elem);
        });
      }, 100);
    };
  });

  return (
    <Fragment>
      <span className="uisheet screen-darken"></span>
      <div
        className="header"
        style={{
          background: `url(${topImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'repeat-y',
          position: 'relative',
          height: `${height}px`,
        }}
      >
        {/* <div className="main-img">
          <div className="container">
            <Image src="/kryptopay-icon.svg" width={160} />
            <h1 className="my-4">
              <span data-setting="app_name">{appName}</span>
            </h1>
            <h4 className="text-white mb-5">
              Production ready <b>Kryptopay Payment Gateway</b>
            </h4>
          </div>
        </div> */}

        <Container>
          <Nav className="rounded navbar navbar-expand-lg navbar-light top-1">
            <Container>
              <Navbar.Brand href="/" className="mx-2 d-flex align-items-center">
                <Image src="/kryptopay-icon.svg" width={30} />
                <h5 className="logo-title mx-3">{appName}</h5>
              </Navbar.Brand>
              {/* <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setOpen3(!open3)} />
              <Navbar.Collapse id="basic-navbar-nav" in={open3}>
                <ul className="mb-2 navbar-nav ms-auto mb-lg-0 d-flex align-items-start">
                  <Nav.Item as="li" className="me-3 mb-2 mb-sm-0">
                    <Nav.Link href="/auth/sign-in">Sign in</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="me-3 mb-2 mb-sm-0">
                    <Nav.Link href="/auth/sign-up">Sign up</Nav.Link>
                  </Nav.Item>
                </ul>
              </Navbar.Collapse> */}
            </Container>
          </Nav>
        </Container>
        <Container className="py-3 px-2">
          {loading && (
            <div className="d-flex justify-content-center text-center align-items-center " style={{ height: '80vh' }}>
              Loading payment information ...
            </div>
          )}
          {!loading && (
            <>
              <Row className="px-2 mx-2 d-flex justify-content-center text-center align-items-center mt-3">
                <Col className="fs-1 fw-bold text-white mb-2" sm={12}>
                  Payment Information
                </Col>
              </Row>
              {info && info.oid && info.details && info.details.length > 0 && (
                <Row className="d-flex align-items-center mb-3" style={{ fontSize: '1.5rem' }}>
                  <Col sm={6} md={3} className="text-end fw-bold text-white">
                    Store
                  </Col>
                  <Col sm={6} md={3} className="text-start" style={{ color: '#eee' }}>
                    {info.details[0].company}
                  </Col>
                  <Col sm={6} md={3} className="text-end fw-bold text-white">
                    Order ID
                  </Col>
                  <Col sm={6} md={3} className="text-start" style={{ color: '#eee' }}>
                    #{info.oid}
                  </Col>
                </Row>
              )}
              <Table responsive id="datatable" className="" data-toggle="data-table">
                <thead>
                  <tr style={{ background: '#ffffffcc' }}>
                    <th>Transaction</th>
                    <th>Pay To</th>
                    <th>Cryptocurrency</th>
                    <th>Fiat (USD)</th>
                  </tr>
                </thead>
                <tbody>
                  {info.details &&
                    info.details.map((item) => (
                      <tr key={item.txhash} style={{ background: '#ffffffaa' }}>
                        <td>{`${item.txhash.substring(0, 6)}...${item.txhash.substr(-6)}`}</td>
                        <td>{`${item.to.substring(0, 6)}...${item.to.substr(-6)}`}</td>
                        <td>{`${item.amount} ${item.crypto.toUpperCase()}`}</td>
                        <td>{`${item.fiat.toFixed(3)}`}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </>
          )}
          <div className="text-center mt-2">
            <a href={refer} className="btn btn-success">
              Return to Store
            </a>
          </div>
        </Container>
      </div>
    </Fragment>
  );
};

export default CheckoutConfirmPage;
