import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Card from '../../../components/Card';

import auth5 from '../../../assets/images/auth/05.png';
import { useApiRequest } from '../../../hooks';
import { registerUrl } from '../../../consts';
import { Toaster, withNotAuth } from '../../../helpers';

const SignUp = () => {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // register submit function
  const { response, error, loading, sendRequest } = useApiRequest({
    endpoint: registerUrl,
    method: 'POST',
    data: { name, email, password },
  });

  useEffect(() => {
    if (response) {
      Toaster.success(response.message);
      setTimeout(() => {
        navigate('/auth/sign-in');
      }, 200);
    }
    if (error) {
      Toaster.error(error.message);
    }
  }, [response, error, navigate]);

  return (
    <>
      <section className="login-content">
        <Row className="m-0 align-items-center bg-white vh-100">
          <div className="col-md-6 d-md-block d-none bg-primary p-0 vh-100 overflow-hidden">
            <Image src={auth5} className="Image-fluid gradient-main animated-scaleX" alt="images" />
          </div>
          <Col md="6">
            <Row className="justify-content-center">
              <Col md="10">
                <Card className="card-transparent auth-card shadow-none d-flex justify-content-center mb-0">
                  <Card.Body>
                    <Link to="/" className="navbar-brand d-flex align-items-center mb-3">
                      <Image src="/kryptopay-icon.svg" width={30} />
                      <h4 className="logo-title ms-3">Kryptopay Payment Gateway</h4>
                    </Link>
                    <h2 className="mt-5 mb-2 text-center">Sign Up</h2>
                    <p className="text-center">Create your Gateway account.</p>
                    <Form>
                      <Row>
                        <Col lg="12">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="store-name" className="">
                              Store Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className=""
                              id="full-name"
                              placeholder="Your store's name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg="6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="email" className="">
                              Email
                            </Form.Label>
                            <Form.Control
                              type="email"
                              className=""
                              id="email"
                              placeholder="Email Address"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg="6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="password" className="">
                              Password
                            </Form.Label>
                            <Form.Control
                              type="password"
                              className=""
                              id="password"
                              placeholder="Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                        {/* <Col lg="12" className="d-flex justify-content-center">
                          <Form.Check className="mb-3 form-check">
                            <Form.Check.Input type="checkbox" id="customCheck1" />
                            <Form.Check.Label htmlFor="customCheck1">I agree with the terms of use</Form.Check.Label>
                          </Form.Check>
                        </Col> */}
                      </Row>
                      <div className="d-flex justify-content-center">
                        <Button
                          onClick={async () => await sendRequest()}
                          type="button"
                          variant="primary"
                          disabled={!name || !email || !password}
                        >
                          {loading ? 'Signing Up...' : 'Sign Up'}
                        </Button>
                      </div>
                      {/* <p className="text-center my-3">or sign in with other accounts?</p>
                      <div className="d-flex justify-content-center">
                        <ListGroup as="ul" className="list-group-horizontal list-group-flush">
                          <ListGroup.Item as="li" className="list-group-item border-0 pb-0">
                            <Link to="#">
                              <Image src={facebook} alt="fb" />
                            </Link>
                          </ListGroup.Item>
                          <ListGroup.Item as="li" className="list-group-item border-0 pb-0">
                            <Link to="#">
                              <Image src={google} alt="gm" />
                            </Link>
                          </ListGroup.Item>
                          <ListGroup.Item as="li" className="list-group-item border-0 pb-0">
                            <Link to="#">
                              <Image src={instagram} alt="im" />
                            </Link>
                          </ListGroup.Item>
                          <ListGroup.Item as="li" className="list-group-item border-0 pb-0">
                            <Link to="#">
                              <Image src={linkedin} alt="li" />
                            </Link>
                          </ListGroup.Item>
                        </ListGroup>
                      </div> */}
                      <p className="mt-3 text-center">
                        Already have an Account{' '}
                        <Link to="/auth/sign-in" className="text-underline">
                          Sign In
                        </Link>
                      </p>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {/* <div className="sign-bg sign-bg-right">
              <svg width="280" height="230" viewBox="0 0 421 359" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.05">
                  <rect
                    x="-15.0845"
                    y="154.773"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(-45 -15.0845 154.773)"
                    fill="#3A57E8"
                  />
                  <rect
                    x="149.47"
                    y="319.328"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(-45 149.47 319.328)"
                    fill="#3A57E8"
                  />
                  <rect
                    x="203.936"
                    y="99.543"
                    width="310.286"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(45 203.936 99.543)"
                    fill="#3A57E8"
                  />
                  <rect
                    x="204.316"
                    y="-229.172"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(45 204.316 -229.172)"
                    fill="#3A57E8"
                  />
                </g>
              </svg>
            </div> */}
          </Col>
        </Row>
      </section>
    </>
  );
};

export default withNotAuth(SignUp);
