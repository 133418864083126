import Profile from '../views/admin/profile';
import Transactions from '../views/admin/transactions';
import Balance from '../views/admin/balance';
import Default from '../layouts/dashboard/default';

export const AdminRouter = [
  {
    path: '/',
    element: <Default />,
    children: [
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: 'transactions',
        element: <Transactions />,
      },
      {
        path: 'balance',
        element: <Balance />,
      },
    ],
  },
];
