import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import settingReducer from './setting/reducers';
import userReducer from './user';

export const store = configureStore({
  reducer: {
    setting: settingReducer,
    user: userReducer,
  },
});

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;
